/* eslint-disable @angular-eslint/no-output-native */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AnalyticsService } from '@app/core';
import { AnalyticsEvent } from '@app/core/analytics/analytics.type';
import { DefaultAnalyticsProps } from '@app/core/analytics/default-analytics-props';

export interface TranscriptSectionCopyEvent {
  header: string | null;
  body: string | null;
}

@Component({
  selector: 'omg-healthscribe-transcript-section',
  templateUrl: './transcript-section.component.html',
  styleUrls: ['./transcript-section.component.scss'],
})
export class TranscriptSectionComponent {
  @Input()
  header: string;

  @Input()
  body: string;

  @Input()
  canCopy: boolean;

  @Output()
  copied = new EventEmitter<TranscriptSectionCopyEvent>();

  constructor(private analytics: AnalyticsService) {}

  copySection(e: Event): void {
    const event: TranscriptSectionCopyEvent = {
      header: this.header,
      body: this.body,
    };
    this.copied.emit(event);
    e.stopPropagation();
  }

  analyticsExpandedChange(expanded: boolean): void {
    const event = expanded
      ? AnalyticsEvent.HealthScribeTranscriptSectionExpanded
      : AnalyticsEvent.HealthScribeTranscriptSectionCollapsed;
    this.analytics.track(event, {
      ...DefaultAnalyticsProps,
      component: 'Transcript',
      subcomponent: this.header,
    });
  }
}
