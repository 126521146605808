<omg-collapsible-banner variant="instructional"
                        (expandChange)="onExpandChange($event)">
  <div header
       data-testid="header">HealthScribe is in beta</div>
  <div severity-title>
    @if (showSubmissionConfirmation) {
    <omg-feedback-thanks />
    }
    @else {
    <span>Rate Visit Summary</span>
    }
  </div>
  <div class="feedback-form"
       message>
    <form [formGroup]="form">
      <label class="helpful-prompt-label">
        How helpful was this visit summary?
      </label>
      <omg-button-group class="feedback-options"
                        [buttonGroupControl]="form.controls.helpful"
                        [options]="options">
      </omg-button-group>
      <div class="freetext-feedback-section">
        <label>Tell us why
          <textarea class="feedback-text"
                    [formControl]="form.controls.freetext"
                    omgTextarea>
                  </textarea>
        </label>
      </div>
    </form>
    <div class="action-bar">
      <button omgButton
              class="submit-button"
              type="button"
              [disabled]="!form.controls.helpful.value"
              variant="primary"
              data-testid="submit-button"
              (click)="onSubmit()">
        Submit
      </button>
      <button omgButton
              class="cancel-button"
              type="button"
              variant="flat"
              data-testid="cancel-button"
              (click)="onCancel($event)">
        Cancel
      </button>
    </div>
  </div>
</omg-collapsible-banner>
