import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CollapsibleBannerComponent } from '@app/shared/components/collapsible-banner/collapsible-banner.component';

type HealthScribeHelpfulOption = {
  label: string;
  value: HealthScribeHelpfulValue;
};

type HealthScribeHelpfulValue = '0' | '1' | '2' | '3' | '4';

interface HealthScribeFeedbackForm {
  helpful: FormControl<HealthScribeHelpfulValue | null>;
  freetext: FormControl<string | null>;
}

export interface HealthScribeFeedbackEvent {
  freetext: string | null;
  value: string | null;
}

@Component({
  selector: 'omg-healthscribe-transcript-feedback-form',
  templateUrl: './transcript-feedback-form.component.html',
  styleUrls: ['./transcript-feedback-form.component.scss'],
})
export class TranscriptFeedbackFormComponent {
  form: FormGroup<HealthScribeFeedbackForm>;

  @Output() trackClose = new EventEmitter<Event>();
  @Output() trackOpen = new EventEmitter<Event>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() submit = new EventEmitter<HealthScribeFeedbackEvent>();

  @ViewChild(CollapsibleBannerComponent)
  banner: CollapsibleBannerComponent;

  showSubmissionConfirmation: boolean = false;

  constructor(private formBuilder: FormBuilder) {
    this.initForm();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      helpful: new FormControl<HealthScribeHelpfulValue | null>(null),
      freetext: new FormControl<string | null>(null),
    });
  }

  onExpandChange(expanded?: boolean): void {
    if (expanded === undefined) {
      return;
    } else if (!expanded) {
      this.trackClose.emit();
      this.resetFormValues();
    } else {
      this.trackOpen.emit();
    }
  }

  resetFormValues(): void {
    this.form.reset();
  }
  onSubmit(): void {
    const event: HealthScribeFeedbackEvent = {
      value: this.form.controls.helpful.value,
      freetext: this.form.controls.freetext.value,
    };
    this.submit.emit(event);
    this.banner.collapse();
    this.showSubmissionConfirmation = true;
    setTimeout(() => {
      this.showSubmissionConfirmation = false;
    }, 3000);
  }
  onCancel(event: any): void {
    this.banner.collapse();
  }

  readonly options: HealthScribeHelpfulOption[] = [
    {
      label: '<span><i class="fa-thin fa-face-grin-wide"></i> Extremely</span>',
      value: '4',
    },
    {
      label: '<span><i class="fa-thin fa-face-smile"></i> Very</span>',
      value: '3',
    },
    {
      label: '<span><i class="fa-thin fa-face-meh"></i> Moderately</span>',
      value: '2',
    },
    {
      label: '<span><i class="fa-thin fa-face-frown"></i> Slightly</span>',
      value: '1',
    },
    {
      label:
        '<span><i class="fa-thin fa-face-disappointed"></i> Not at All</span>',
      value: '0',
    },
  ];
}
